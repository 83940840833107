<template>
  <div class="design-page--create mb-10">
    <redirect class="mb-5" title="WIDGET" :route="configPage.backRoute" />
    <div class="d-flex align-center justify-space-between mb-4">
      <div class="page-header">
        <h2 class="page-title mb-2">{{ configPage.title }}</h2>
        <p class="mb-0">Here you can customize the interface of your recommendation widgets.</p>
      </div>
      <v-switch
        :label="enabledWidget ? 'Enabled' : 'Disabled'"
        v-model="enabledWidget"
        @change="onEnabledWidget"
      ></v-switch>
    </div>
    <Notification type="error" :messages="errors" v-if="errors.length > 0" class="mb-4" />
    <v-row v-if="!isLoading" no-gutters class="tab-custom mb-4">
      <v-col cols="12" class="d-flex justify-center align-center tab-custom__header">
        <button
          @click="changeDevice('desktop')"
          :class="`tab-custom__header--tab` + (data.device === 'desktop' ? ' active-device' : '')"
        >
          <v-icon>mdi-television</v-icon>
          Desktop
        </button>
        <button
          @click="changeDevice('mobile')"
          :class="`tab-custom__header--tab` + (data.device === 'mobile' ? ' active-device' : '')"
        >
          <v-icon>mdi-cellphone</v-icon>
          Mobile
        </button>
      </v-col>
      <v-col v-if="data.device === 'desktop'" cols="12" class="px-10">
        <Header :data="data.widgetCustomize.header" />
        <carousel
          :navigationEnabled="true"
          :per-page="data.widgetCustomize.numberOfProducts.productsPerSlide"
          :paginationSize="10"
          :mouse-drag="true"
          :navigation-next-label="nextLabel"
          :navigation-prev-label="prevLabel"
        >
          <slide v-for="i in 16" :key="i">
            <div class="pa-4">
              <ItemCart
                :dataPrice="data.widgetCustomize.productPrice"
                :dataName="data.widgetCustomize.productName"
                :dataAction="data.widgetCustomize.addCartButton"
                :isHover="true"
              />
            </div>
          </slide>
        </carousel>
      </v-col>
      <v-col v-else cols="12" class="pa-6">
        <div class="iframe__container mb-4">
          <img
            src="https://minio.lattehub.com/img/200/200/resize/605c62651288b96616c6babe/2021/09/05/lattehub-image-6134cd0f3987182279997b50.png"
            alt=""
            srcset=""
          />
          <div class="device-content__container">
            <Header v-if="data.widgetCustomize.layout === '1'" :data="data.widgetCustomize.header" />
            <carousel
              v-if="data.widgetCustomize.layout === '1'"
              style="height: 500px"
              :navigationEnabled="false"
              :per-page="5"
              :paginationSize="6"
              :navigation-next-label="nextLabel"
              :navigation-prev-label="prevLabel"
              :mouse-drag="true"
            >
              <slide v-for="i in 16" :key="i">
                <div class="pa-4">
                  <ItemCart
                    :dataAction="data.widgetCustomize.addCartButton"
                    :dataPrice="data.widgetCustomize.productPrice"
                    :dataName="data.widgetCustomize.productName"
                  />
                </div>
              </slide>
            </carousel>
            <div
              style="max-height: 500px; overflow-y: auto"
              class="selected"
              v-if="data.widgetCustomize.layout === '2'"
            >
              <Header v-if="data.widgetCustomize.layout === '2'" :data="data.widgetCustomize.header" />
              <div class="d-flex" v-for="i in 4" :key="i">
                <ItemCart
                  :dataAction="data.widgetCustomize.addCartButton"
                  :dataPrice="data.widgetCustomize.productPrice"
                  :dataName="data.widgetCustomize.productName"
                />
                <ItemCart
                  :dataAction="data.widgetCustomize.addCartButton"
                  :dataPrice="data.widgetCustomize.productPrice"
                  :dataName="data.widgetCustomize.productName"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-center justify-center">
          <v-btn color="primary" depressed @click="isChangeLayout = true">Change Layout</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!isLoading && data.widgetType !== 'designCode'" no-gutters class="design-page--container">
      <v-col v-if="!isPfy" cols="12" class="pa-4">
        <h3 class="mb-4">Customize</h3>
        <div>
          <label class="d-block font-weight-bold gray--text mb-1">Header</label>
          <SelectionStyle
            @updataData="getDataStyle"
            :data="data.widgetCustomize.header"
            :type="'header'"
            class="mb-2"
          />
          <v-text-field v-model="data.widgetCustomize.header.textTitle" hide-details></v-text-field>
        </div>
        <v-divider class="my-4"></v-divider>
        <div>
          <label class="d-block font-weight-bold gray--text mb-1">Product name</label>
          <SelectionStyle @updataData="getDataStyle" :data="data.widgetCustomize.productName" :type="'productName'" />
          <!-- <v-text-field v-model="data.widgetCustomize.productName.textTitle" class="mt-3"></v-text-field> -->
        </div>
        <v-divider class="my-4"></v-divider>
        <div>
          <label class="d-block font-weight-bold gray--text mb-1">Product price</label>
          <SelectionStyle @updataData="getDataStyle" :data="data.widgetCustomize.productPrice" :type="'productPrice'" />
          <!-- <v-text-field v-model="data.widgetCustomize.productPrice.textTitle" class="mt-3"></v-text-field> -->
        </div>
        <v-divider class="my-4"></v-divider>
        <div>
          <label class="d-block font-weight-bold gray--text mb-1">Number of products</label>
          <v-col
            :cols="
              data.widgetType === 'cartRecommend' ||
              data.widgetType === 'moreCollection' ||
              data.widgetType === 'bestSeller' ||
              data.widgetType === 'recentView' ||
              data.widgetType === 'alsoBought'
                ? 8
                : 4
            "
            class="d-flex pa-0"
            style="gap:12px"
          >
            <div
              v-if="
                data.widgetType === 'cartRecommend' ||
                  data.widgetType === 'moreCollection' ||
                  data.widgetType === 'bestSeller' ||
                  data.widgetType === 'recentView' ||
                  data.widgetType === 'alsoBought'
              "
            >
              <label>Number of products to be show</label>
              <v-select
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 15, 18, 20, 25]"
                v-model="data.widgetCustomize.numberOfProducts.productsToBeShown"
              ></v-select>
            </div>
            <div>
              <label>Max products per slide</label>
              <v-select
                :items="data.device === 'desktop' ? [2, 4, 6] : [1, 2]"
                v-model="data.widgetCustomize.numberOfProducts.productsPerSlide"
              ></v-select>
            </div>
          </v-col>
        </div>
        <v-divider class="my-4"></v-divider>
        <v-switch
          v-model="data.widgetCustomize.addCartButton.enabled"
          label="Show add to cart button"
          hide-details
        ></v-switch>
        <v-divider class="my-4"></v-divider>
        <div>
          <label class="d-block font-weight-bold gray--text mb-1">Call to action text</label>
          <SelectionStyle
            :isAlign="false"
            :isTextColor="false"
            @updataData="getDataStyle"
            :data="data.widgetCustomize.addCartButton.callToAction"
            :type="'callToAction'"
          />
        </div>
        <v-divider class="my-4"></v-divider>
        <div>
          <label class="d-block font-weight-bold gray--text"
            >Where do you want to place your recommendation widgets?</label
          >
          <v-checkbox v-model="data.widgetCustomize.place.cartPage" label="Cart Page" hide-details />
          <!-- <v-checkbox
          hide-details=""
          v-model="data.widgetCustomize.place.categoryPage"
          label="Category Page"
        /> -->
          <v-checkbox
            v-if="data.widgetType === 'bestSeller' || data.widgetType === 'recentView'"
            hide-details=""
            v-model="data.widgetCustomize.place.homePage"
            label="Home page"
          />
          <v-checkbox v-model="data.widgetCustomize.place.productPage" label="Product Page" hide-details />
          <div class="ml-8" v-if="data.widgetCustomize.place.productPage">
            <v-checkbox
              label="Choose specific product pages to display widgets"
              v-model="data.widgetCustomize.place.page.isShowAtSpecificPage"
              hide-details
              class="mb-2"
            />
            <div v-if="data.widgetCustomize.place.page.isShowAtSpecificPage">
              <v-select
                :items="[
                  { name: 'Products', id: 'products' },
                  { name: 'Collections', id: 'collections' },
                ]"
                small
                style="width:300px"
                v-model="data.widgetCustomize.place.page.selectType"
                item-text="name"
                item-value="id"
                hide-details
                class="mb-2"
              ></v-select>
              <div v-if="data.widgetCustomize.place.page.selectType === 'collections'">
                <v-btn
                  color="primary"
                  class="mr-3"
                  depressed
                  v-if="data.widgetCustomize.place.page.selectType === 'collections'"
                  @click="configDialogSelection({ type: 'collections', model: 'collectionIds' })"
                >
                  Select Collection</v-btn
                >
                <span>{{ data.widgetCustomize.place.page.collectionIds.length }} collection selected </span>
              </div>
              <div v-if="data.widgetCustomize.place.page.selectType === 'products'">
                <v-btn
                  color="primary"
                  class="mr-3"
                  depressed
                  @click="configDialogSelection({ type: 'products', model: 'productIds' })"
                >
                  Select product</v-btn
                >
                <span>{{ data.widgetCustomize.place.page.productIds.length }} product selected </span>
              </div>
            </div>
          </div>
        </div>
        <v-divider class="my-4"></v-divider>
        <div>
          <label class="d-block font-weight-bold gray--text">Display List Product</label>
          <v-radio-group v-model="data.widgetCustomize.displayList">
            <v-radio label="Slide" :value="`slide`" />
            <v-radio label="Multiple Row" :value="`rows`" />
          </v-radio-group>
        </div>
      </v-col>

      <v-col cols="12" class="pa-4" v-if="!isLoading && data.widgetType === 'cartRecommend'">
        <h3>Product page recommendation with</h3>
        <v-checkbox checked hide-details="" v-model="data.widgetCustomize.showWith" value="niche" label="Niche" />
        <v-checkbox
          checked
          hide-details=""
          v-model="data.widgetCustomize.showWith"
          value="sameTitle"
          label="Same Title"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isLoading && data.widgetType === 'pickForYou'" no-gutters class="mt-10 design-page--container">
      <v-col cols="12   pa-3">
        <div><h3>Customize</h3></div>
      </v-col>
      <v-col cols="12  pa-3">
        <div><h3>Header</h3></div>
        <SelectionStyle @updataData="getDataStyle" :data="data.widgetCustomize.header" :type="'header'" />
        <v-text-field v-model="data.widgetCustomize.header.textTitle" class="mt-3"></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-divider class="mt-3"></v-divider>
      </v-col>
      <v-col cols="12  pa-3">
        <div><h3>Product name</h3></div>
        <SelectionStyle @updataData="getDataStyle" :data="data.widgetCustomize.productName" :type="'productName'" />
        <!-- <v-text-field v-model="data.widgetCustomize.productName.textTitle" class="mt-3"></v-text-field> -->
      </v-col>
      <v-col cols="12">
        <v-divider class="mt-3"></v-divider>
      </v-col>
      <v-col cols="12  pa-3">
        <div><h3>Product price</h3></div>
        <SelectionStyle @updataData="getDataStyle" :data="data.widgetCustomize.productPrice" :type="'productPrice'" />
        <!-- <v-text-field v-model="data.widgetCustomize.productPrice.textTitle" class="mt-3"></v-text-field> -->
      </v-col>
      <v-col cols="12">
        <v-divider class="mt-3"></v-divider>
      </v-col>

      <v-divider></v-divider>

      <v-col cols="12  pa-3">
        <div><h3>Product page recommendation with</h3></div>
        <v-checkbox checked hide-details="" v-model="data.widgetCustomize.showWith" value="niche" label="Niche" />
        <v-checkbox
          checked
          hide-details=""
          v-model="data.widgetCustomize.showWith"
          value="sameTitle"
          label="Same Title"
        />
        <v-checkbox
          checked
          hide-details=""
          v-model="data.widgetCustomize.showWith"
          value="smartNicheRecommend"
          label="Smart Niche Recommend"
        />
      </v-col>
      <v-divider></v-divider>
    </v-row>

    <v-row v-if="!isLoading && data.widgetType === 'designCode'" no-gutters class="mt-10 design-page--container">
      <v-col cols="12   pa-3">
        <div><h3>Customize</h3></div>
      </v-col>
      <v-col cols="12  pa-3">
        <div><h3>Header</h3></div>
        <SelectionStyle @updataData="getDataStyle" :data="data.widgetCustomize.header" :type="'header'" />
        <v-text-field v-model="data.widgetCustomize.header.textTitle" class="mt-3"></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-divider class="mt-3"></v-divider>
      </v-col>

      <v-divider></v-divider>

      <v-col cols="12  pa-3">
        <div><h3>Product page recommendation with</h3></div>
        <v-checkbox checked hide-details="" v-model="data.widgetCustomize.showWith" value="designCode" label="Design" />
      </v-col>
      <v-divider></v-divider>
    </v-row>

    <v-col v-if="!isLoading" cols="12" class="d-flex mt-8 pa-0">
      <v-spacer></v-spacer>
      <v-btn class="mr-4" depressed @click="resetDefault">Reset to default</v-btn>
      <v-btn :loading="isLoading" color="primary" depressed @click="onSubmit"> Submit</v-btn>
    </v-col>
    <v-dialog persistent v-model="isChangeLayout" width="700" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline">
          {{ 'Change Layout' }}
        </v-card-title>

        <!-- Close icon -->
        <div class="close-icon" @click="isChangeLayout = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <!-- Content -->
        <v-card-text class="">
          <v-row class="pa-5">
            <v-col cols="6">
              <div
                @click="changeLayout('1')"
                :class="`box-image-layout` + (data.widgetCustomize.layout === '1' ? ' active-layout' : '')"
              >
                <img :src="imagesLayout.image1" alt="" srcset="" />
              </div>
            </v-col>
            <v-col cols="6">
              <div
                @click="changeLayout('2')"
                :class="`box-image-layout` + (data.widgetCustomize.layout === '2' ? ' active-layout' : '')"
              >
                <img :src="imagesLayout.image2" alt="" srcset="" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Footer -->
      </v-card>
    </v-dialog>
    <BeforeLeavePage />
    <v-dialog :scrollable="false" v-model="dialog" width="900" content-class="l-dialog">
      <SelectionItems
        :model="configSelectionItem.model"
        :type="configSelectionItem.type"
        @returnData="getDataSelected"
        :dialog.sync="dialog"
        :dataSelected="configSelectionItem.data"
        :isSuperShow="true"
      />
    </v-dialog>
  </div>
</template>

<script>
import ItemCart from '../component/ItemCart';
import SelectionStyle from '../component/SelectionStyle';
import Header from '../component/Header';
import SelectionItems from '../../component/SelectionItems';
import { convertIdsToArrays } from '@/helpers/convertData';

import { widgetApi } from '@/apis/widget';
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
    ItemCart,
    SelectionStyle,
    SelectionItems,
    Header,
  },
  data() {
    let defaultConfig = {
      device: 'desktop',
      widgetType: 'bestSeller',
      widgetCustomize: {
        productPrice: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '14px',
          italic: false,
          underline: false,
          textTitle: 'Title ',
        },
        productName: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '14px',
          italic: false,
          underline: false,
          textTitle: 'Programmer Label Personalized',
        },
        place: {
          cartPage: true,
          categoryPage: false,
          homePage: false,
          productPage: true,
          page: {
            collectionIds: [],
            productIds: [],
            selectType: 'products',
            isShowAtSpecificPage: false,
          },
        },
        header: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '25px',
          italic: false,
          underline: false,
          textTitle: 'Handpicked products',
        },
        addCartButton: {
          enabled: true,
          callToAction: {
            align: 'center',
            bold: false,
            fontColor: '#000000',
            fontSize: '12px',
            italic: false,
            underline: false,
            textTitle: 'Title ',
          },
        },
        layout: '1',
        numberOfProducts: {
          productsPerSlide: 6,
          productsToBeShown: 12,
        },
      },
    };
    return {
      nameTheme: '',
      nextLabel: '<i class="fas fa-chevron-right"></i>',
      prevLabel: `<i class="fas fa-chevron-left"></i>`,
      configPage: {
        title: 'Handpicked products',
        backRoute: 'website.apps.widgets.handpicked',
      },
      configSelectionItem: {
        type: 'products',
        model: 'targetIds',
        data: [],
      },
      isChangeLayout: false,
      tab: null,
      items: ['Desktop', 'Mobile'],
      text: 'or incididunt ut labor',
      errors: [],
      dialog: false,
      isLoading: false,
      dataSever: {
        mobile: null,
        desktop: null,
      },
      dataDefault: {
        collectionIds: [],
        productIds: [],
      },
      data: Object.assign({}, defaultConfig),
      defaultConfig,
      imagesLayout: {
        image1:
          'https://minio.lattehub.com/img/200/200/resize/605c62651288b96616c6babe/2021/09/05/lattehub-image-6134cbf11e43c5d96806bd8c.png',
        image2:
          'https://minio.lattehub.com/img/200/200/resize/605c62651288b96616c6babe/2021/09/05/lattehub-image-6134cbf16b65ba7d4936ff37.png',
      },
      enabledWidget: false,
    };
  },
  computed: {
    isPfy() {
      return this.data && this.data.widgetType === 'pickForYou' ? true : false;
    },
    allowPage() {
      if (!this.data) return false;
      return (
        this.data.widgetType === 'cartRecommend' ||
        this.data.widgetType === 'moreCollection' ||
        this.data.widgetType === 'bestSeller' ||
        this.data.widgetType === 'recentView' ||
        this.data.widgetType === 'alsoBought'
      );
    },
  },
  created() {
    this.defaultConfig.widgetType = this.$route.params.widgetType;
    this.loadData({ widgetType: this.$route.params.widgetType });
    if (this.$route.params.widgetType) {
      if (this.$route.params.widgetType === 'cartRecommend') {
        this.configPage.title = 'Cart recommendations';
        this.defaultConfig.widgetCustomize.header.textTitle = 'Cart recommendations';
        this.configPage.backRoute = 'website.apps.widgets.list';
      }
      if (this.$route.params.widgetType === 'moreCollection') {
        this.configPage.title = 'Products from the same collections';
        this.defaultConfig.widgetCustomize.header.textTitle = 'More from {{collection_name}}';
        this.configPage.backRoute = 'website.apps.widgets.list';
      }
      if (this.$route.params.widgetType === 'bestSeller') {
        this.configPage.title = 'Store best sellers';
        this.defaultConfig.widgetCustomize.header.textTitle = 'Store best sellers';
        this.configPage.backRoute = 'website.apps.widgets.list';
      }
      if (this.$route.params.widgetType === 'recentView') {
        this.configPage.title = 'Recently viewed & featured products';
        this.defaultConfig.widgetCustomize.header.textTitle = 'Recently viewed & featured products';
        this.configPage.backRoute = 'website.apps.widgets.list';
      }
      if (this.$route.params.widgetType === 'alsoBought') {
        this.configPage.title = 'Who bought this also bought';
        this.defaultConfig.widgetCustomize.header.textTitle = 'Who bought this also bought';
        this.configPage.backRoute = 'website.apps.widgets.list';
      }
      if (this.$route.params.widgetType === 'pickForYou') {
        this.configPage.title = 'Pick For You';
        this.defaultConfig.widgetCustomize.header.textTitle = 'Pick For You';
        this.configPage.backRoute = 'website.apps.widgets.list';
      }
      if (this.$route.params.widgetType === 'designCode') {
        this.configPage.title = 'More featuring with artwork';
        this.defaultConfig.widgetCustomize.header.textTitle = 'More featuring with artwork';
        this.configPage.backRoute = 'website.apps.widgets.list';
      }
    }
    this.nameTheme = localStorage.getItem(`nameTheme`);
  },
  watch: {
    'data.device': function() {
      if (this.dataSever[this.data.device].header) {
        this.data.widgetCustomize = this.dataSever[this.data.device];
      } else {
        this.data.widgetCustomize = Object.assign({}, this.defaultConfig.widgetCustomize);
      }
    },
  },
  methods: {
    async resetDefault() {
      let [cartRecommend, moreCollection, bestSeller, recentView, alsoBought, handpickedProduct, pickForYou] = [
        {
          ...this.defaultConfig,
          widgetType: 'cartRecommend',
          activated: true,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Cart recommendations',
            },
            place: {
              cartPage: true,
              homePage: true,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'moreCollection',
          activated: true,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'More from {{collection_name}}',
            },
            place: {
              cartPage: true,
              homePage: false,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'bestSeller',
          activated: true,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Store best sellers',
            },
            place: {
              homePage: true,
              cartPage: false,
              productPage: false,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'recentView',
          activated: true,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Recently viewed & featured recommendations',
            },
            place: {
              cartPage: true,
              homePage: true,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'alsoBought',
          activated: true,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Who bought this also bought',
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'handpickedProduct',
          activated: true,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'pickForYou',
          activated: true,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Pick For You',
            },
            showWith: 'sameTitle',
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
      ];
      let payload = {
        cartRecommend,
        moreCollection,
        bestSeller,
        recentView,
        alsoBought,
        handpickedProduct,
        pickForYou,
      };
      await widgetApi.create({ productWidget: payload[this.$route.params.widgetType] });
      await this.loadData({ widgetType: this.$route.params.widgetType });
    },
    async loadData(params) {
      try {
        this.isLoading = true;
        this.dataSever = (await widgetApi.get(params)).data.data;
        this.data = {
          device: 'desktop',
          widgetType: this.$route.params.widgetType,
          widgetCustomize: this.dataSever['desktop'].header
            ? this.dataSever['desktop']
            : Object.assign({}, this.defaultConfig.widgetCustomize),
        };
        if (this.$route.params.widgetType === 'alsoBought') {
          this.data.widgetCustomize.place.productPage = true;
          this.defaultConfig.widgetCustomize.place.productPage = true;
        }
      } catch (error) {
        console.log('error', error);
        //empty
      }
      this.isLoading = false;
    },
    getDataStyle(data, type) {
      if (type === 'header') {
        data.textTitle = this.data.widgetCustomize.header.textTitle;
      }
      if (type === 'callToAction') {
        this.data.widgetCustomize['addCartButton'][type] = Object.assign({}, data);
      } else {
        this.data.widgetCustomize[type] = Object.assign({}, data);
      }
    },
    updateValue(e) {
      console.log(e);
    },
    changeLayout(type) {
      this.data.widgetCustomize.layout = type;
      this.isChangeLayout = false;
    },
    changeDevice(device) {
      console.log('device', device);
      this.data.device = device;
    },
    onCancel() {},
    async onSubmit() {
      try {
        if (this.$route.params.widgetType !== 'handpickedProduct') {
          if (
            this.data.widgetCustomize.place.page.selectType === 'products' &&
            this.data.widgetCustomize.place.page &&
            this.data.widgetCustomize.place.page.isShowAtSpecificPage &&
            this.data.widgetCustomize.place.productPage
          ) {
            this.data.widgetCustomize.place.page.productIds = this.data.widgetCustomize.place.page.productIds.map(it =>
              it._id ? it._id : it,
            );
            this.data.widgetCustomize.place.page.collectionIds = [];
          }
          if (
            this.data.widgetCustomize.place.page.selectType === 'collections' &&
            this.data.widgetCustomize.place.page &&
            this.data.widgetCustomize.place.page.isShowAtSpecificPage &&
            this.data.widgetCustomize.place.productPage
          ) {
            this.data.widgetCustomize.place.page.collectionIds = this.data.widgetCustomize.place.page.collectionIds.map(
              it => (it._id ? it._id : it),
            );
            this.data.widgetCustomize.place.page.productIds = [];
          }
          if (!this.data.widgetCustomize.place.page.isShowAtSpecificPage) {
            this.data.widgetCustomize.place.page.collectionIds = [];
            this.data.widgetCustomize.place.page.productIds = [];
          }
          if (!this.data.widgetCustomize.place.productPage) {
            this.data.widgetCustomize.place.page.isShowAtSpecificPage = false;
            this.data.widgetCustomize.place.page.collectionIds = [];
            this.data.widgetCustomize.place.page.productIds = [];
          }
        }
        await widgetApi.create({ productWidget: this.data });
      } catch (error) {
        console.log('error', error);
        //empty
      }
    },
    async configDialogSelection(data) {
      this.configSelectionItem = Object.assign({}, data);

      try {
        if (
          this.data.widgetCustomize.place.productPage &&
          this.data.widgetCustomize.place.page &&
          this.data.widgetCustomize.place.page.productIds &&
          data.model === 'productIds'
        ) {
          let ids = this.data.widgetCustomize.place.page.productIds;
          if (ids.length > 0 && typeof ids[0] === 'string') {
            this.data.widgetCustomize.place.page.productIds = await convertIdsToArrays('product', ids);
          }
          this.configSelectionItem.data = this.data.widgetCustomize.place.page.productIds;
        }
        //productPage
        if (
          this.data.widgetCustomize.place.productPage &&
          this.data.widgetCustomize.place.page &&
          this.data.widgetCustomize.place.page.collectionIds &&
          data.model === 'collectionIds'
        ) {
          let ids = this.data.widgetCustomize.place.page.collectionIds;
          if (ids.length > 0 && typeof ids[0] === 'string') {
            this.data.widgetCustomize.place.page.collectionIds = await convertIdsToArrays('collection', ids);
          }
          this.configSelectionItem.data = this.data.widgetCustomize.place.page.collectionIds;
        }
        this.dialog = true;
        // this.configSelectionItem.data = this.dataDefault[data.model];
      } catch (error) {}
    },
    getDataSelected(data, model) {
      console.log('model', model, data);
      this.data.widgetCustomize.place.page[model] = JSON.parse(JSON.stringify(data));
      console.log('this.data.widgetCustomize.place', this.data.widgetCustomize.place);
      // this.configSelectionItem.data = JSON.parse(JSON.stringify(data));
    },
    async onEnabledWidget() {
      await widgetApi.create({
        productWidget: {
          widgetType: this.data.widgetType,
          activated: this.enabledWidget,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.design-page--create {
  .design-page--container {
    border: 1px solid #e6ebf0;
    border-radius: 12px;
  }
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar {
    width: 2px;
    height: 30px;
    visibility: hidden;
  }

  /* Track */
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar-track {
    background: #dddddddd;
  }

  /* Handle */
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar-thumb {
    background: #dddddddd;
  }

  /* Handle on hover */
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar-thumb:hover {
    background: #dddddddd;
  }
  min-width: 1024px;

  .tab-custom {
    min-height: 500px;
    border: 1px solid #e6ebf0;
    border-radius: 12px;
    .tab-custom__header {
      border-bottom: 1px solid #e6ebf0;
      height: 56px;
      align-items: center;
      display: flex;
      gap: 12px;
      &--tab {
        height: 100%;
        align-items: center;
        display: flex;
        gap: 4px;
        cursor: pointer;
        font-weight: 500;
        color: gray;
      }
      .active-device {
        border-bottom: 2px solid var(--v-primary-base);
        color: var(--v-primary-base);
        i {
          color: var(--v-primary-base);
        }
      }
    }
    .iframe__container {
      width: 374px;
      margin: auto;
      position: relative;
      img {
        width: 100%;
      }
      .device-content__container {
        position: absolute;
        padding: 65px 27px;
        top: 75px;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.box-image-layout {
  min-height: 400px;
  border: 1px solid #e6ebf0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active-layout {
    border: 1px solid #4181cb;
  }
  img {
    width: 200px;
    height: 350px;
  }
}
.VueCarousel-pagination {
  display: none;
}
.VueCarousel-wrapper {
  overflow-x: none;
}
.VueCarousel-wrapper::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  visibility: hidden;
}

/* Track */
.VueCarousel-wrapper::-webkit-scrollbar-track {
  background: #dddddddd;
}

/* Handle */
.VueCarousel-wrapper::-webkit-scrollbar-thumb {
  background: #dddddddd;
  border-radius: 10px;
}

/* Handle on hover */
.VueCarousel-wrapper::-webkit-scrollbar-thumb:hover {
  background: #dddddddd;
}
.VueCarousel-navigation-button:focus {
  outline: none !important;
  /* outline: 1px solid lightblue; */
}
</style>
